export type StreamErrorType = "USER_MESSAGE_EMPTY" | "OTHER";

export class StreamError extends Error {
  errorCode: number;
  type: StreamErrorType;
  data: any;

  constructor(message: string, errorCode: number, type: StreamErrorType, data?: any) {
    super(message);
    this.name = "StreamError";
    this.errorCode = errorCode;
    this.type = type;
    this.data = data;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, StreamError);
    }
  }
}
