import { ensureUnreachable } from "@/shared_lib/utils/utils";
import alertIcon from "@icons/alert-triangle.svg?url";
import checkCircleIcon from "@icons/check-circle.svg?url";
import closeIcon from "@icons/close.svg?url";
import helpIcon from "@icons/help-circle.svg?url";
import infoIcon from "@icons/info.svg?url";
import lightingIcon from "@icons/lightning.svg?url";
import errorIcon from "@icons/x-circle.svg?url";
import { cn } from "@repo/utils";
import Image from "next/image";
import { ExternalToast, toast as sonnerToast } from "sonner";
import { Button } from "./button";
import { Typography } from "./typography";

type ToastType = "neutral" | "info" | "success" | "alert" | "warning" | "error";
type ToastPosition = "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top-center" | "bottom-center";

export interface ToastProps {
  title: string;
  message?: string;
  type: ToastType;
  duration?: number;
  position?: ToastPosition;
  primaryAction?: {
    label: string;
    action: () => void;
  };
  secondaryAction?: {
    label: string;
    action: () => void;
  };
}

interface ToastContentProps extends ToastProps {
  dismiss: () => void;
}

const DEFAULT_DURATION = 5000;

export function toast(params: ToastProps) {
  let toastProps: ExternalToast = {
    duration: params.duration || DEFAULT_DURATION,
    position: params.position || "bottom-right",
  };

  const toastId = sonnerToast.custom(
    (t: any) => <ToastContent {...params} dismiss={() => sonnerToast.dismiss(t)} />,
    toastProps,
  );

  return {
    dismiss: () => sonnerToast.dismiss(toastId),
  };
}

const ToastContent = (params: ToastContentProps) => {
  const { type, primaryAction, secondaryAction, dismiss, message, title } = params;
  const { icon, bgColorClassName, borderColorClassName } = getToastStyle(type);

  return (
    <div
      className={cn(
        "shadow-card flex w-[400px] rounded-2xl border border-solid p-4",
        bgColorClassName,
        borderColorClassName,
      )}
    >
      <div className="mr-2">
        <Image src={icon} alt="Toast icon" width={16} height={16} />
      </div>
      <div className="flex-1">
        <Typography variant="label" className="text-content-title mb-2 block">
          {title}
        </Typography>
        <Typography variant="caption" className="text-content-support block">
          {message}
        </Typography>
        {(primaryAction || secondaryAction) && (
          <div className="mt-2 flex flex-row gap-2">
            {primaryAction && (
              <Button
                onClick={primaryAction.action}
                text={primaryAction.label}
                variant="primary"
                size="small"
                type="button"
                className="mt-1"
              />
            )}
            {secondaryAction && (
              <Button
                onClick={secondaryAction.action}
                text={secondaryAction.label}
                variant="secondary"
                size="small"
                type="button"
                className="mt-1"
              />
            )}
          </div>
        )}
      </div>
      <button
        aria-label="Close toast"
        role="button"
        className="self-start border-none bg-transparent p-0 focus:outline-none"
        onClick={dismiss}
      >
        <Image src={closeIcon} alt="Close icon" width={16} height={16} />
      </button>
    </div>
  );
};

const getToastStyle = (type: ToastType) => {
  let icon = lightingIcon;
  let bgColorClassName = "bg-surface-main";
  let borderColorClassName = "border-border-primary";

  switch (type) {
    case "neutral":
      icon = lightingIcon;
      bgColorClassName = "bg-surface-main";
      borderColorClassName = "border-border-components";
      break;
    case "info":
      icon = infoIcon;
      bgColorClassName = "bg-surface-infoLight";
      borderColorClassName = "border-border-info";
      break;
    case "success":
      icon = checkCircleIcon;
      bgColorClassName = "bg-surface-successLight";
      borderColorClassName = "border-border-success";
      break;
    case "alert":
      icon = helpIcon;
      bgColorClassName = "bg-surface-alertLight";
      borderColorClassName = "border-border-alert";
      break;
    case "warning":
      icon = alertIcon;
      bgColorClassName = "bg-surface-warningLight";
      borderColorClassName = "border-border-warning";
      break;
    case "error":
      icon = errorIcon;
      bgColorClassName = "bg-surface-errorLight";
      borderColorClassName = "border-border-error";
      break;
    default:
      ensureUnreachable(type);
  }

  return { icon, bgColorClassName, borderColorClassName };
};
