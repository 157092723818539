const variants = {
  hero: {
    element: "h1",
    styles: "text-[80px] leading-[88px] md:text-6xl",
  },
  title1: {
    element: "h1",
    styles: "text-6xl leading-[72px] md:text-5xl",
  },
  title2: {
    element: "h2",
    styles: "text-5xl leading-[56px] md:text-4xl",
  },
  title3: {
    element: "h3",
    styles: "text-4xl leading-12 md:text-3xl",
  },
  title4: {
    element: "h4",
    styles: "text-3xl leading-10  md:text-2xl",
  },
  title5: {
    element: "h5",
    styles: "text-2xl leading-8 ",
  },
  title6: {
    element: "h6",
    styles: "text-xl leading-6 ",
  },
  body: {
    element: "p",
    styles: "text-base leading-5",
  },
  copy: {
    element: "p",
    styles: "text-lg leading-6",
  },
  label: {
    element: "label",
    styles: "text-sm  leading-4",
  },
  caption: {
    element: "span",
    styles: "text-xs  leading-[14px] ",
  },
  small: {
    element: "span",
    styles: "text-xs leading-3 ",
  },
} as const;

type TypographyVariant = keyof typeof variants;

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: TypographyVariant;
  weight?: "300" | "400" | "500" | "600" | "700";
  className?: string;
  children: React.ReactNode;
}

export const Typography = ({
  variant = "body",
  weight = "400",
  className = "",
  children,
  ...props
}: TypographyProps) => {
  const { element: Element, styles } = variants[variant];

  const weightClass = {
    "300": "font-light",
    "400": "font-normal",
    "500": "font-medium",
    "600": "font-semibold",
    "700": "font-bold",
  }[weight];

  return (
    <Element className={`${styles} ${weightClass} ${className}`} {...props}>
      {children}
    </Element>
  );
};
